import React, { useEffect, useRef, useState } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { useForm } from 'react-hook-form';
import cx from 'classnames';
import { create } from 'zustand';
import { Button, Icon } from '@/components';
import { Routes } from '@/types';
import { toast } from 'react-toastify';

const hasWindow = typeof window !== `undefined`;
const bootstrap = hasWindow && import('bootstrap');

const emailFooter = (isBG) => {
  if (isBG) {
    return `

Екипа на Очна Болница "Луксор

гр.Пловдив, бул. България №59
lasercorrection.bg
office@luxorclinic.com
+359 877 881 115`;
  }

  return `

Eye Hospital "Luxor" Team

47A Bulgaria blvd., Plovdiv, BG
lasercorrection.bg/en
office@luxorclinic.com
+359 877 881 115`;
};

export const useRequestInfoModal = create<any>((set) => ({
  isOpen: false,
  open: () => {
    return set(() => ({ isOpen: true }));
  },
  close: () => {
    return set(() => ({ isOpen: false }));
  },
}));

const leafletText = (isBG, scheduleAppointment) => {
  if (!scheduleAppointment) return '';

  if (!isBG) {
    return `
    
Follow the link below to download a leaflet more information about the hospital
https://lasercorrection.bg/leaflets/luxor-en.pdf`;
  }
  return `
 
Линк с брошурата с повече информация за болницата
https://lasercorrection.bg/leaflets/luxor-bg.pdf`;
};

const clientText = (isBG, scheduleAppointment) => {
  if (!isBG) {
    return `Thank you for choosing Eye Hospital "Luxor"!

You have scheduled an appointment.
We will get in touch within 48 hours to confirm a date for your examination.

Price: 103 euro

Examination includes:
- Vision acuity test (with dilated and constricted pupils)
- Biomicroscopy (examination of the anterior segment of the eye under microscope)
- Autorefractokeratometry (measuring the optical power of the eye)
- Corneal topography (color mapping of the cornea)
- Wavefront analysis (digital representation of higher-order aberrations)
- Pachymetry (measurement of thickness of the cornea)
- Ophthalmoscopy (eye fundus test)${leafletText(isBG, scheduleAppointment)}${emailFooter(isBG)}`;
  }

  return `Благодарим ви че избрахте Очна Болница "Луксор"!
  
Вие се регистрирахте за преглед.
Ние ще ви се обадим до 48 часа, за да потвърдите дата за вашия преглед.

Цена на прегледа: 200лв

Вашият преглед включва:
- Определяне на зрителна острота (на тесни и широки зеници)
- Биомикроскопия (изследване на предния сегмент на окото под микроскоп)
- Авторефрактокератометрия (измерване на диоптричната сила на окото)
- Корнеална топография (изготвяне на цветна карта на роговицата)
- Уейвфронт анализ (цифров израз на високостепенните аберации на окото)
- Пахиметрия (определяне на дебелина на роговицата)
- Офталмоскопия (изследване на очните дъна)${leafletText(isBG, scheduleAppointment)}${emailFooter(isBG)}`;
};

const luxorText = (data, receiveInformation) => {
  const { name, email, phone } = data;
  return `Име: ${name}
Емайл: ${email}
Телефон: ${phone}
Поискал брошура: ${receiveInformation ? 'Да' : 'Не'}`;
};

export const RequestInfoModal = ({ opened, onClose }) => {
  const { t, language } = useI18next();
  const [modal, setModal] = useState(null);
  const [receiveInformation, setReceiveInformation] = useState(false);
  const [scheduleAppointment, setScheduleAppointment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const close = useRequestInfoModal((store) => store.close);

  const modalRef = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true });

  const onSubmit = async (data) => {
    const { name, email, captcha } = data;
    const hospitalName = t('g.title');
    const isBG = language === 'bg';
    const text = clientText(isBG, scheduleAppointment);

    const userFeedback = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        from: `${hospitalName} <office@luxorclinic.com>`,
        to: `${name} <${email}>`,
        text,
        subject: isBG
          ? 'Регистрация за преглед - Очна Болница "Луксор"'
          : 'Appointment Schedule - Eye Hospital "Luxor"',
        captcha,
      }),
    };

    const luxorFeedback = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: 'office@luxorclinic.com',
        // to: 'yshterev@gmail.com',
        from: 'no-reply-lasercorrection@luxorclinic.com',
        text: luxorText(data, receiveInformation),
        subject: 'Регистрация за преглед през lasercorrection.bg',
        captcha,
      }),
    };

    setIsLoading(true);

    try {
      // await fetch(`http://localhost:8788/api/send-mail`, userFeedback);
      // await fetch(`http://localhost:8788/api/send-mail`, luxorFeedback);
      await fetch(`/api/send-mail`, userFeedback);
      await fetch(`/api/send-mail`, luxorFeedback);
    } catch {
      toast.error(t('g.failed-to-send'));
    } finally {
      setIsLoading(false);
      toast.success(t('g.sent'));
      modal?.hide();
    }
  };

  useEffect(() => {
    bootstrap?.then(({ Modal }) => {
      const modalEl = modalRef.current;
      const modal = new Modal(modalEl, { backdrop: true });

      setModal(modal);
    });
  }, []);

  useEffect(() => {
    const modalEl = modalRef.current as HTMLElement;
    if (!modalEl) return;

    modalEl.addEventListener('hidden.bs.modal', onClose);

    return () => modalEl.removeEventListener('hidden.bs.modal', onClose);
  }, [onClose]);

  useEffect(() => {
    if (!modal) return;

    if (opened) {
      setReceiveInformation(false);
      setScheduleAppointment(false);
      reset();
      modal?.show();
    } else {
      modal?.hide();
    }
  }, [opened, modal, reset]);

  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex={-1}
      aria-labelledby={t('contacts.request-info')}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-between mb-4 mt-1">
              <h3 className="mb-0">{t('contacts.request-info')}</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="content-modal__wrapper p-1">
              <form action="">
                <div className="row">
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className={cx('form-control', { 'is-invalid': errors.name })}
                      placeholder={t('contacts.name')}
                      {...register('name', { required: true })}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className={cx('form-control', { 'is-invalid': errors.email })}
                      placeholder={t('contacts.email')}
                      {...register('email', { required: true, validate: (value) => /\S+@\S+\.\S+/.test(value) })}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className={cx('form-control', { 'is-invalid': errors.phone })}
                      placeholder={t('contacts.phone')}
                      {...register('phone', { required: true })}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <input
                      type="text"
                      className={cx('form-control', { 'is-invalid': errors.captcha })}
                      placeholder={t('g.captcha')}
                      {...register('captcha', { required: true })}
                    />
                    <div className="form-text">{t('g.captcha-text')}</div>
                  </div>
                  <div className="col-12">
                    <div className="form-check my-3">
                      <input
                        type="checkbox"
                        role={'button'}
                        className="form-check-input"
                        id="requestInfoCheckbox1"
                        checked={receiveInformation}
                        onChange={(e) => setReceiveInformation(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="requestInfoCheckbox1" role={'button'}>
                        {t('contacts.checkbox.1')}
                      </label>
                    </div>

                    <div className="form-check mt-3 mb-4">
                      <input
                        type="checkbox"
                        role={'button'}
                        className="form-check-input"
                        id="requestInfoCheckbox2"
                        checked={scheduleAppointment}
                        onChange={(e) => setScheduleAppointment(e.target.checked)}
                      />
                      <label className="form-check-label" htmlFor="requestInfoCheckbox2" role={'button'}>
                        {t('contacts.checkbox.2')}
                      </label>
                    </div>

                    <div className={cx({ 'text-gray': !scheduleAppointment })}>
                      <div className="h5">{t('contacts.examination')}</div>
                      <p className="mъ-3">{t('contacts.includes')}:</p>
                      <ul className="list-unstyled mb-5">
                        {t('contacts.request-info-list')
                          .split('\n\n')
                          .map((line, index) => (
                            <li key={index} className="my-2">
                              <Icon name="check" size={30} className="me-2" />
                              {line}
                            </li>
                          ))}
                      </ul>
                      <p>{t('contacts.request-info-body.1')}</p>
                      <p>
                        {t('contacts.request-info-body.2')}{' '}
                        <Link onClick={close} to={Routes.LASER_VISION}>
                          {t('header.laser-vision-correction')}.
                        </Link>
                      </p>
                    </div>
                    <div className="mt-3">
                      <Button
                        loading={isLoading}
                        disabled={!scheduleAppointment || isLoading}
                        title={t('contacts.send')}
                        className={'text-uppercase shadow btn-success'}
                        onClick={handleSubmit(onSubmit)}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
