import React from 'react';
import { Button, Icon } from '@/components';
import { Patients, PatientsMapping } from '@/types';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useYouTubeModal } from '../youtube-modal/youtube-modal';
import cx from 'classnames';
import './home-patient.scss';

export const HomePatient: React.FC<{
  image?: Array<string> | string;
  text?: string;
  title?: string;
  className?: string;
  patient?: Patients;
  videoId?: string;
}> = ({ image, text, title, className, patient, videoId }) => {
  const { open } = useYouTubeModal();
  const { t } = useI18next();

  let cardImages: string | string[] = Array.isArray(image) ? image : [image];
  let cardTitle = title;
  let cardText = text;
  let cardVideoId = videoId;

  if (patient && PatientsMapping[patient]) {
    cardImages = PatientsMapping[patient].image;
    cardImages = Array.isArray(cardImages) ? cardImages : [cardImages];
    cardVideoId = PatientsMapping[patient].videoId;

    cardTitle = t(`patients.${Patients[patient]}`);
    cardText = t(`patients.${Patients[patient]}-slogan`);
  }

  return (
    <div className={cx('home-patient text-center', className)}>
      {cardImages?.map((img, index) => (
        <img
          src={img}
          className="home-patient--image"
          width="73"
          height="130"
          alt={cardTitle}
          loading="lazy"
          key={index}
        />
      ))}
      <div className="my-3 h5 text-uppercase">{cardTitle}</div>
      <div className="mt-3">
        <Icon name={'star-fill'} size={24} className="me-2 text-success" />
        <Icon name={'star-fill'} size={24} className="me-2 text-success" />
        <Icon name={'star-fill'} size={24} className="me-2 text-success" />
        <Icon name={'star-fill'} size={24} className="me-2 text-success" />
        <Icon name={'star-fill'} size={24} className="text-success" />
      </div>
      <div className="my-4">&quot;{cardText}&quot;</div>
      {cardVideoId && (
        <div className="text-center">
          <Button
            icon={<Icon name={'play-fill'} size={19} />}
            title={cardTitle}
            className="text-uppercase btn-success shadow"
            onClick={() => open(cardVideoId)}
          >
            {t('g.video')}
          </Button>
        </div>
      )}
    </div>
  );
};
