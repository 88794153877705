import React from 'react';

export const validationError = (error, field, customMessage = '') => {
  if (!error) return null;

  return (
    <div className="invalid-feedback">
      {error?.type === 'validate' && (error?.message || customMessage || `${field} is invalid`)}
      {error?.type === 'required' && (customMessage || `${field} is required`)}
      {error?.type === 'maxLength' && (customMessage || `${field} is too long`)}
      {error?.type === 'minLength' && (customMessage || `${field} is too short`)}
      {error?.type === 'pattern' && error.message}
    </div>
  );
};

export const getLuxorText = (data) => {
  const {
    name,
    phone,
    sex,
    rightEye,
    dioptricPower,
    eyeDiseases,
    email,
    age,
    city,
    leftEye,
    combatSports,
    contactLenses,
  } = data;
  return `
Регистриран персонален тест

Име: ${name}
Имейл: ${email}
Телефон: ${phone}
Години: ${age}
Пол: ${sex}
Град: ${city}
Диоптри - дясно око: ${rightEye}
Диоптри - ляво око: ${leftEye}
Променял ли се е диоптера ви с повече от 0.5 за последните 6 месеца? ${dioptricPower}
Носите ли контактни лещи в момента? ${contactLenses}
Известно ли Ви е, да имате очни заболявания? ${eyeDiseases}
Практикувате ли контактни бойни спортове- бокс, карате или други? ${combatSports}`;
};

export const over40 = (data, isBG) => {
  const { name, rightEye, age, leftEye } = data;
  if (isBG) {
    return `Здравейте ${name},

От това, което сте посочили във Вашето запитване:
  
Години: ${age}
Брой и диоптри за далечно разстояние: 
Дясно око: ${rightEye} диоптъра 
Ляво око: ${leftEye} диоптъра
  
Най-добрият и дългосрочен вариант е поставянето на високотехнологични
многофокусни лещи, даващи възможност за добро зрение наблизо и надалеч. Тези
лещи са перфектният избор на млади и активно трудещи се хора желаещи
независимост от очила и контактни лещи.
  
Модерните разбирания за поставяне на високотехнологични вътреочни лещи са,
че те се поставят когато нормалния ритъм на живот на пациента е нарушен.
Когато започнете да изпитвате затруднение и да разчитате все повече и повече
на Вашите очила, това е добър момент за премахване на тази Ваша зависимост.
  
Ние работим със здравната каса и ако вземете направление, прегледа е 2.90лв
(потребителската такса). По време на прегледа, нашите лекари ще Ви дадат
обстойна информация за най-правилните лещи. Цената на прегледа без
направление е 40 лева.
  
Операцията се поема от здравната каса. От страна на пациента се покрива
доплащане за високотехнологичните многофокуснати лещи, които трябва да се
имплантират. Цените са между 3000 и 3500 лева за едната леща в зависимост от
това дали имате или не роговичен астигматизъм.
  
Лещите са на световноизвестни и признати фирми, като Alcon, Oculentis и Carl
Zeiss. Работим и с много други фирми, като нашето мото е, че всеки пациент е
уникален и трябва да се подбере най-правилната леща по време на прегледа.
  
Относно технологията, ние работим с най-новите технологии, като оперативният
отвор за поставяне на лещата е само около 2.0 мм. Минималният разрез
гарантира бързо възстановяване и перфектни резултати.
  
Всички прегледи при нас се извършват с предварително записване и затова е
най-добре да се обадите в клиниката, за да си запазите свободен час: 0877
881 115, 032 968 881 – Регистратура.
  
Допълнителна информация за високотехнологични вътреочни лещи можете да
получите от сайта на Очна Болница Луксор:
  
https://www.luxorclinic.com/multifocal-lens/
`;
  }

  return `Dear ${name},

Based on the information in your request:

Age: ${age}
Diopters for far distance right eye: ${rightEye} D
Diopters for far distance left eye ${leftEye} D

The best and long-term option is implantation of high-tech multifocal 
lenses, which provide good vision at close and far distance. These lenses
are the perfect choice for young and active people, who want to be 
independent of glasses and contact lenses.

The modern understanding of the implantation of high-tech intraocular
lenses is that they are implanted when the normal rhythm of the patient’s 
life is disrupted. When you start experience difficulties and rely more 
and more on your glasses, this is a good moment for you to eliminate 
this dependence.

The surgery is covered by the Health Fund for Bulgarian citizens. The 
patient covers the cost of the high-tech multifocal lenses, which must 
be implanted. The prices vary depending on whether you have corneal 
astigmatism or not. Follow the link to check all prices:

https://lasercorrection.bg/en/prices-financing/

The lenses are manufactured by world-class and recognized companies, 
such as Alcon, Oculentis and Carl Zeiss. We also work with many 
other companies, as our motto is that each patient is unique, 
and during the examination must be chosen the most suitable lens.

We work with the latest technologies, as the operative opening for 
the implantation of the lens is only about 2.0 mm. The minimal 
incision guarantees fast recovery and perfect results.

All examinations in our clinic are done with preliminary appointment, 
therefore it is best if you contact the clinic to 
schedule your appointment: +359 877 881 115, +359 32 968 881 – Reception.

You can find more information about the high-tech intraocular lenses 
at the website of Eye Clinic Luxor:

https://www.luxorclinic.com/en/multifocal-lens/`;
};

export const under40 = (data, isBG) => {
  const { name, rightEye, age, leftEye } = data;

  if (isBG) {
    return `Здравейте ${name},

Благодарим Ви за проявения интерес към нашата клиника и към лазерната 
корекция на зрението. Избора на този тип процедури ще ви направи 
независими, от очилата и контактните лещи. При хора под 40 години, 
резултатите от лазерна корекция на зрението са отлични и Вие няма да се 
нуждаете от допълнителна зрителна корекция. След тази възраст започват 
естествени процеси на стареене в окото и може да се наложи носенето на 
очила за близко разстояние, като тези процеси са строго индивидуални.
      
От информацията, която сте предоставили: години: ${age} и диоптри – дясно 
око: ${rightEye} диоптъра; ляво око: ${leftEye} диоптъра и евентуален 
съпътстващ астигматизъм, Вие може да сте подходящ кандидат за лазерна 
корекция на зрението.

Всеки месец плануваме няколко дати за лазерна корекция на зрението. Преди 
самата корекция е необходимо да се направи обстоен преглед. Той струва 200 
лева, продължава около два часа и определя правилния и точен план за Вашата 
лазерна корекция.

Прегледа включва различни персонални изследвания на специализирани апарати в 
нашата болница и дава прецизен профил на зрението на пациента. Изисква се 
поставяне на капки в очите, за разширяване на зениците. На база всички 
изследвания по време на персоналния преглед се създава индивидуален план на 
лечение, който впоследстве се залага в лазерната система.

Препоръчително е да не сте носили контактни лещи поне 1 седмица преди 
обстойния преглед в нашата болница!!!

Лазерна корекция на зрението предлагаме от 2008-ма година и досега 
помогнахме на хиляди пациенти да захвърлят очилата и контактните лещи. 
Лазерната система за премахване на диоптри късогледство, далекогледство и 
астигматизъм, с която разполагаме е четвърто последно поколение ексимерен 
лазер MEL 90 на фирма Carl-Zeiss. За извършване на подготвителните стъпки от 
лазерната корекция на зрението нашата болница е оборудвана с последно 
поколение фемтосекунден лазер (Carl-Zeiss Visumax 800). Това гарантира 
безупречна прецизност, още по-голяма предвидимост на резултатите и по-бързо 
възстановяване след процедурата. Цената на тази процедура (Femto-LASIK) е по 
1900 лева за едно око, като всички детайли ще Ви бъдат обяснени по време на 
обстойния преглед.

Можете да намерите отговори на най-често задаваните въпроси за лазерната 
корекция на сайта на Очна Болница Луксор: 

https://lasercorrection.bg/testimonials/`;
  }

  return `Dear ${name},

Thank you for your interest in our clinic and in the laser vision correction. 
The choice of this procedure will make you independent of glasses and contact 
lenses.

For people under 40, the results from laser vision correction are excellent, 
and you will not need further eye correction. After this age the natural aging 
process of the eye starts, which may require the wearing of glasses for close 
distance, since this process is highly individual.

Based on the information, which you have submitted: 
age: ${age}; diopters: right eye: ${rightEye} D; left eye ${leftEye} D, 
and possible concomitant astigmatism, you could be suitable applicant for laser 
correction of the vision.

The examination includes different personalized tests with specialized devices in 
our clinic, and provides precise profile of the patient’s vision. The pupils 
must be dilated with eye drops. Based on all the test results during the personal 
examination, we design individual treatment plan, which is uploaded in the laser 
system.

It is recommended that you stop wearing contact lenses at least 1 week before 
the detailed examination in our clinic!

We offer laser eye correction since 2008, and so far we have helped thousands of 
patients to get rid of the glasses and the contact lenses. Our laser system, 
which makes correction of near-sightedness, far-sightedness and astigmatism, 
is fourth last generation excimer laser MEL 90 produced by Carl-Zeiss. 

The preparation for the laser correction in our clinic is done with last 
generation femtosecond laser (Carl-Zeiss Visumax 800). This guarantees 
faultless precision, higher predictability of the results and faster recovery 
after the procedure. You can check the Femto-LASIK procedure prices at our website:

https://lasercorrection.bg/en/prices-financing/

You can find answers to the most frequently asked questions about the 
laser correction at the website of Eye Hospital Luxor:

https://lasercorrection.bg/en/testimonials/
`;
};

export const languageLinkFix = (language) => (language === 'bg' ? '' : `${language}/`);
