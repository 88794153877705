exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-astigmatism-tsx": () => import("./../../../src/pages/astigmatism.tsx" /* webpackChunkName: "component---src-pages-astigmatism-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-dr-dimitar-taskov-tsx": () => import("./../../../src/pages/dr-dimitar-taskov.tsx" /* webpackChunkName: "component---src-pages-dr-dimitar-taskov-tsx" */),
  "component---src-pages-dr-georgi-taskov-tsx": () => import("./../../../src/pages/dr-georgi-taskov.tsx" /* webpackChunkName: "component---src-pages-dr-georgi-taskov-tsx" */),
  "component---src-pages-dr-svetoslava-karparova-tsx": () => import("./../../../src/pages/dr-svetoslava-karparova.tsx" /* webpackChunkName: "component---src-pages-dr-svetoslava-karparova-tsx" */),
  "component---src-pages-dr-todor-taskov-tsx": () => import("./../../../src/pages/dr-todor-taskov.tsx" /* webpackChunkName: "component---src-pages-dr-todor-taskov-tsx" */),
  "component---src-pages-engagement-program-tsx": () => import("./../../../src/pages/engagement-program.tsx" /* webpackChunkName: "component---src-pages-engagement-program-tsx" */),
  "component---src-pages-far-sightedness-hypermetropia-tsx": () => import("./../../../src/pages/far-sightedness-hypermetropia.tsx" /* webpackChunkName: "component---src-pages-far-sightedness-hypermetropia-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-laser-vision-correction-tsx": () => import("./../../../src/pages/laser-vision-correction.tsx" /* webpackChunkName: "component---src-pages-laser-vision-correction-tsx" */),
  "component---src-pages-news-events-campaigns-tsx": () => import("./../../../src/pages/news-events-campaigns.tsx" /* webpackChunkName: "component---src-pages-news-events-campaigns-tsx" */),
  "component---src-pages-prices-financing-tsx": () => import("./../../../src/pages/prices-financing.tsx" /* webpackChunkName: "component---src-pages-prices-financing-tsx" */),
  "component---src-pages-short-sightedness-myopia-tsx": () => import("./../../../src/pages/short-sightedness-myopia.tsx" /* webpackChunkName: "component---src-pages-short-sightedness-myopia-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why-us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */)
}

