import { useI18next } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import { Icon } from '../icon/icon';
import './home-hero.scss';

// From Modernizr - CSS Transition events
const whichAnimationEvent = () => {
  const el = document.createElement('fakeelement');
  const animations = {
    animation: 'animationend',
    OAnimation: 'oAnimationEnd',
    MozAnimation: 'animationend',
    WebkitAnimation: 'webkitAnimationEnd',
  };

  for (const t in animations) {
    if (el.style[t] !== undefined) {
      return animations[t];
    }
  }
};

const animationDone = (event) => {
  const target = event.target;
  const sibling = target.nextElementSibling || target.parentElement.firstElementChild;

  if (target.classList.contains('fade-in')) {
    target.classList.remove('fade-in');

    setTimeout(function () {
      target.classList.add('fade-out');
    }, 2000);
  }

  if (target.classList.contains('fade-out')) {
    target.classList.add('invisible');
    target.classList.remove('fade-out');

    sibling.classList.remove('invisible');
    sibling.classList.add('fade-in');
  }
};

export const HomeHero = () => {
  const { t } = useI18next();

  useEffect(() => {
    const tipsWrapper = document.getElementById('tip-wrapper');
    const animationEvent = whichAnimationEvent();

    if (!animationEvent) {
      return;
    }

    tipsWrapper.addEventListener(animationEvent, animationDone);

    setTimeout(function () {
      tipsWrapper.firstElementChild.classList.add('fade-out');
    }, 2000);
  }, []);

  return (
    <section className="home-video">
      <img
        className="home-video__img-fallback d-block d-sm-none"
        src="/img/home-mobile.webp"
        alt={t('header.hero-subtext.1')}
      />
      <img
        className="home-video__img-fallback d-none d-sm-block d-md-none"
        src="/img/home-tablet.webp"
        alt={t('header.hero-subtext.1')}
        loading="lazy"
      />
      <img
        className="home-video__img-fallback d-none d-md-block d-xxl-none"
        src="/img/home-laptop.webp"
        alt={t('header.hero-subtext.1')}
        loading="lazy"
      />

      <img
        className="home-video__img-fallback d-none d-xxl-block"
        src="/img/home-big.webp"
        alt={t('header.hero-subtext.1')}
        loading="lazy"
      />

      <div className="d-flex align-items-center w-100 text-center position-relative flex-column">
        <h1 className="home-video__slogan">{t('header.hero-text')}</h1>
        <div id="tip-wrapper" className="home__tip__wrapper d-flex justify-content-center position-relative w-100">
          <div id="tip-one" className="home__tip position-absolute">
            <Icon name="check" size={60} className="home__tip-check" />
            <span className="home__tip-text">{t('header.hero-subtext.1')}</span>
          </div>

          <div id="tip-two" className="home__tip invisible position-absolute">
            <Icon name="check" size={60} className="home__tip-check" />
            <span className="home__tip-text">{t('header.hero-subtext.2')}</span>
          </div>

          <div id="tip-three" className="home__tip invisible position-absolute">
            <Icon name="check" size={60} className="home__tip-check" />
            <span className="home__tip-text">{t('header.hero-subtext.3')}</span>
          </div>
        </div>
      </div>
    </section>
  );
};
