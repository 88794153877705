import React from 'react';
import cx from 'classnames';

export const Button = ({
  title = null,
  icon = null,
  className,
  iconClassName = 'me-1',
  children = null,
  loading = false,
  ...rest
}) => {
  if (loading) {
    return (
      <button role="button" aria-label={title} title={title} disabled className={cx('btn', className)} {...rest}>
        <div className="spinner-border spinner-border-sm me-2" role="status" />
        Sending ...
      </button>
    );
  }
  return (
    <button role="button" aria-label={title} title={title} className={cx('btn', className)} {...rest}>
      {icon && <span className={iconClassName}>{icon}</span>}
      {title && children && <span>{children}</span>}
      {title && !children && <span className="align-middle">{title}</span>}
    </button>
  );
};
