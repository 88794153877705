import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Routes, Team, TeamMapping } from '@/types';
import { AspectRatio } from '@/components';
import cx from 'classnames';
import './styles.scss';

export const TeamCard: React.FC<{
  img?: string;
  link?: Routes | string;
  text?: string;
  title?: string;
  className?: string;
  member?: Team;
  imageOnly?: boolean;
}> = ({ img, link, text, title, className, member, imageOnly = false }) => {
  const { t } = useI18next();

  let cardImage = img;
  let cardTitle = title;
  let cardText = text;

  if (member && TeamMapping[member]) {
    cardImage = TeamMapping[member].img;
    cardTitle = t(`team.${Team[member]}`);
    cardText = t(TeamMapping[member].text);
  }

  const LinkWrapper = ({ children }) => {
    if (link) {
      return <Link to={link}>{children}</Link>;
    }

    return <>{children}</>;
  };

  if (link) {
    return (
      <div className={cx('d-flex justify-content-center', className)}>
        <LinkWrapper>
          <div className={cx('team-card shadow', { 'team-card--link': link })}>
            <AspectRatio ratio={13 / 16}>
              <img className="team-card__img" src={cardImage} alt={cardTitle} loading="lazy" />
            </AspectRatio>
            {!imageOnly && <div className="team-card__bio py-1 text-white text-center">{t('g.biography')}</div>}
            {!imageOnly && (
              <div className="team-card__body py-2 text-white text-center">
                <h2 className="team-card__title ">{cardTitle}</h2>
                <div className="team-card__text">{cardText}</div>
              </div>
            )}
          </div>
        </LinkWrapper>
      </div>
    );
  }

  return (
    <div className={cx('d-flex justify-content-center', className)}>
      <div className={cx('team-card shadow', { 'team-card--link': link })}>
        <AspectRatio ratio={13 / 16}>
          <img className="team-card__img" src={cardImage} alt={cardTitle} loading="lazy" />
        </AspectRatio>
        {!imageOnly && (
          <div className="team-card__body py-2 text-white text-center">
            <h2 className="team-card__title">{cardTitle}</h2>
            <div className="team-card__text">{cardText}</div>
          </div>
        )}
      </div>
    </div>
  );
};
