import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Icon, Button } from '@/components';
import { Routes } from '@/types';
import cx from 'classnames';
import './footer.scss';
import { useRequestInfoModal } from '@/components/request-info-modal/request-info-modal';

export const Footer = () => {
  const { t, language } = useI18next();
  const isBG = language === 'bg';
  const currentYear = new Date().getFullYear();
  const logoURLFix = isBG ? '' : '-en';
  const openAppointment = useRequestInfoModal(({ open }) => open);

  return (
    <footer className="footer text-white mt-auto mega-footer bg-gray-900 text-center text-md-start w-100">
      <div className="container">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 py-7">
          <div className="col pb-8 pb-lg-0">
            <h1 className="h5 mb-0 text-capitalize">{t('g.title')}</h1>
            <img src={`/img/logo-white${logoURLFix}.svg`} alt="Luxor Logo" className="logo-image mt-4" />
            <div className="mt-4 text-gray-500">{t('g.address')}</div>
            <div className="mt-2">
              <a href={`mailto:${t('g.office-email')}`} className="footer__link">
                {t('g.office-email')}
              </a>
            </div>
            <div className="mt-2 text-gray-500">{t('g.phone')}</div>
          </div>
          <div className="col pb-8 pb-lg-0">
            <h1 className="h5 mb-0 text-capitalize text-capitalize">{t('footer.links.title')}</h1>
            <div className="mt-3">
              <Link className="footer__link" to={Routes.MYOPIA}>
                {t('footer.links.1')}
              </Link>
            </div>
            <div className="mt-3">
              <Link className="footer__link" to={Routes.HYPERMETROPIA}>
                {t('footer.links.2')}
              </Link>
            </div>
            <div className="mt-3">
              <Link className="footer__link" to={Routes.ASTIGMATISM}>
                {t('footer.links.3')}
              </Link>
            </div>
            <div className="mt-3">
              <Link className="footer__link" to={Routes.ENGAGEMENT}>
                {t('footer.links.4')}
              </Link>
            </div>
          </div>
          <div className="col pb-8 pb-lg-0">
            <h1 className="h5 mb-0 text-capitalize">{t('footer.about.title')}</h1>
            <div className="mt-3">
              <Link className="footer__link" to={Routes.TEAM}>
                {t('footer.about.1')}
              </Link>
            </div>
            <div className="mt-3">
              <Link className="footer__link" to={Routes.TECHNOLOGY}>
                {t('footer.about.2')}
              </Link>
            </div>
            <div className="mt-3">
              <Link className="footer__link" to={Routes.TESTIMONIALS}>
                {t('footer.about.3')}
              </Link>
            </div>
            <div className="mt-3">
              <Link className="footer__link" to={Routes.CAMPAIGNS}>
                {t('footer.about.4')}
              </Link>
            </div>
          </div>
          <div className="col">
            <h1 className="h5 mb-0 text-capitalize">{t('footer.social.title')}</h1>
            <div className="footer__item mdl-list__item mt-4">
              <a
                href="https://www.facebook.com/luxorclinic"
                title={'Facebook Page'}
                aria-label="Facebook Page"
                className="text-white"
                target="_blank"
                rel={'noreferrer nofollow'}
              >
                <Icon name="facebook" size={32} className="me-2" />
              </a>
            </div>
            <div className="mt-4">
              <Link to={Routes.CONTACTS}>
                <Button title={t('header.contact-us')} className={cx('text-uppercase btn-primary')} />
              </Link>
            </div>
            <div className="mt-3">
              <Button
                title={t('header.appointment')}
                onClick={openAppointment}
                className={cx('text-uppercase btn-green')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-800 py-3">
        <div className="container">
          <div className="row">
            <div className="col-12 text-gray-500 small ">
              {t('g.rights')} © {currentYear}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
