import React from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

export const NavLink = ({ item }) => {
  const { t } = useI18next();
  const translateKey = `header.${item.replace(/\//g, '')}`;

  return (
    <Link to={item} key={item} className="text-decoration-none">
      <div className="header__navlink ps-4" title={t(translateKey)}>
        {t(translateKey)}
      </div>
    </Link>
  );
};
