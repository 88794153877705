import React, { useEffect } from 'react';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { Icon } from '../icon/icon';
import { Routes } from '@/types';
import { Button, LanguageSwitch, SideMenu } from '@/components';
import { useWindowScroll } from '@/hooks/use-window-scroll';
import { useSideMenu } from '../side-menu/side-menu';
import cx from 'classnames';
import './styles.scss';
import { usePersonalTestModal } from '../personal-test-modal/personal-test-modal';
import { useRequestInfoModal } from '../request-info-modal/request-info-modal';

export const Header = ({ path }) => {
  const { t, language } = useI18next();
  const [scroll] = useWindowScroll();
  const isBG = language === 'bg';
  const logoURLFix = isBG ? '' : '-en';
  const languageParam = isBG ? '' : `${language}/`;
  const isOpaque = path !== `${Routes.HOME}${languageParam}` || scroll.y > 0;
  const sideMenu = useSideMenu();
  const { close } = sideMenu;
  const personalTestModal = usePersonalTestModal();
  const requestInfoModal = useRequestInfoModal();

  useEffect(() => {
    close();
  }, [path, close]);

  const menuItems = [
    Routes.LASER_VISION,
    Routes.WHY_US,
    Routes.TESTIMONIALS,
    Routes.CAMPAIGNS,
    Routes.FINANCING,
    Routes.ENGAGEMENT,
  ];

  const handleOpenPersonalTest = () => {
    personalTestModal.open();
  };

  const handleOpenRequestInfo = () => {
    requestInfoModal.open();
  };

  return (
    <nav
      className={cx('header d-flex flex-column justify-content-center', {
        'header--opaque': isOpaque,
      })}
    >
      <div className="header__desktop d-none d-xl-flex container-fluid container-fluid--custom">
        <div className="header__section d-flex flex-column justify-content-around m-3 mb-0">
          <div className="header__section d-flex justify-content-between mb-3">
            <Link to="/">
              {isOpaque && (
                <img
                  className="header__logo mt-1"
                  width="160"
                  height="30"
                  loading="lazy"
                  src={`/img/logo${logoURLFix}.svg`}
                  alt="Luxor Logo"
                />
              )}
              {!isOpaque && (
                <img
                  className="header__logo mt-1"
                  width="160"
                  loading="lazy"
                  height="30"
                  src={`/img/logo-white${logoURLFix}.svg`}
                  alt="Luxor Logo"
                />
              )}
            </Link>
            <div>
              <Button
                title={t('g.phone')}
                className={cx('header__phone text-uppercase me-3', {
                  shadow: !isOpaque,
                  'header__phone--black': isOpaque,
                })}
              >
                <Icon name="phone-fill" size={14} className="me-2" />
                <span>{t('g.phone')}</span>
              </Button>
              <Link to={Routes.CONTACTS}>
                <Button title={t('header.contact-us')} className={cx('text-uppercase shadow me-3 btn-primary')} />
              </Link>
              <Button
                title={t('header.personal-test')}
                className={cx('text-uppercase shadow me-3 btn-danger')}
                onClick={handleOpenPersonalTest}
              />
              <Button
                title={t('header.appointment')}
                className={cx('text-uppercase shadow me-3 btn-green')}
                onClick={handleOpenRequestInfo}
              />
            </div>
          </div>
          <div className="header__section d-flex justify-content-between">
            <div>
              {menuItems.map((item) => (
                <Link
                  to={item}
                  key={item}
                  className={cx('header__link py-2 me-3 text-decoration-none', {
                    'header__link--active': path?.includes(item),
                  })}
                >
                  {t(`header.${item.replace(/\//g, '')}`)}
                </Link>
              ))}
            </div>
            <LanguageSwitch className="header__flags--desktop" />
          </div>
        </div>
      </div>

      <div className="header__mobile d-flex d-xl-none flex-column justify-content-around m-3 mb-0">
        <div className="d-flex justify-content-between">
          <div className="header__logomenu">
            <div
              className="mobile-menu-toggle d-inline-block p-1 me-2"
              role="button"
              title="Menu"
              onClick={() => sideMenu.open()}
            >
              <Icon name="list" className={cx(isOpaque ? 'text-black' : 'text-white')}></Icon>
            </div>
            <SideMenu menuItems={menuItems} opened={sideMenu.isOpen} onClose={() => sideMenu.close()} />
            <Link to="/">
              {isOpaque && <img className="header__logo" src={`/img/logo${logoURLFix}.svg`} alt="Luxor Logo" />}
              {!isOpaque && <img className="header__logo" src={`/img/logo-white${logoURLFix}.svg`} alt="Luxor Logo" />}
            </Link>
          </div>
          <div>
            <Button
              title={t('g.phone')}
              className={cx('header__phone text-uppercase me-3 header__phone--mobile', {
                shadow: !isOpaque,
                'header__phone--black': isOpaque,
              })}
            >
              <Icon name="phone-fill" size={14} className="me-2" />
              <span>{t('g.phone')}</span>
            </Button>
            <Link to={Routes.CONTACTS} className="header__contact-us-mobile">
              <Button title={t('header.contact-us')} className={'text-uppercase shadow me-3 btn-primary'} />
            </Link>
            <Button
              title={t('header.personal-test')}
              className={'text-uppercase shadow me-3 btn-danger header__test-mobile'}
              onClick={handleOpenPersonalTest}
            />
            <Button
              title={t('header.appointment')}
              className={'text-uppercase shadow me-3 btn-green header__appointment-mobile'}
              onClick={handleOpenRequestInfo}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};
