import React from 'react';
import { Footer, Header } from '@/components';
import { useYouTubeModal, YouTubeModal } from '@/components/youtube-modal/youtube-modal';
import { ContentModal, useContentModal } from '@/components/content-modal/content-modal';
import { PersonalTestModal, usePersonalTestModal } from '@/components/personal-test-modal/personal-test-modal';
import { RequestInfoModal, useRequestInfoModal } from '@/components/request-info-modal/request-info-modal';
import { ToastContainer } from 'react-toastify';

const Layout = (props) => {
  const { children, path } = props;
  const { isOpen, id: videoId, close } = useYouTubeModal();
  const contentModal = useContentModal();
  const personalTestModal = usePersonalTestModal();
  const requestInfoModal = useRequestInfoModal();

  return (
    <>
      <Header path={path} />
      <main className="flex-shrink-0">{children}</main>
      <Footer />
      <ToastContainer position="bottom-right" />
      <YouTubeModal opened={isOpen} onClose={close} id={videoId} />
      <ContentModal opened={contentModal.isOpen} content={contentModal.content} onClose={contentModal.close} />
      <PersonalTestModal opened={personalTestModal.isOpen} onClose={personalTestModal.close} />
      <RequestInfoModal opened={requestInfoModal.isOpen} onClose={requestInfoModal.close} />
    </>
  );
};

export default Layout;
