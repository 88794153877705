import React from 'react';
import './styles.scss';

export const AspectRatio = ({ ratio, children }) => {
  const style = { '--ratio': ratio } as React.CSSProperties;

  return (
    <div className="aspect-ratio" style={style}>
      {children}
    </div>
  );
};
