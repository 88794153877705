import React, { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import { create } from 'zustand';
import './styles.scss';

const hasWindow = typeof window !== `undefined`;
const bootstrap = hasWindow && import('bootstrap');

export const useYouTubeModal = create<any>((set) => ({
  isOpen: false,
  id: null,
  open: (id) => {
    return set(() => ({ isOpen: true, id }));
  },
  close: () => {
    return set(() => ({ isOpen: false, id: null }));
  },
}));

export const YouTubeModal = ({ opened, id, onClose }) => {
  const options = {
    height: '100%',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const [modal, setModal] = useState(null);
  const modalRef = useRef();

  useEffect(() => {
    bootstrap?.then(({ Modal }) => {
      const modalEl = modalRef.current;
      const modal = new Modal(modalEl, { backdrop: true });

      setModal(modal);
    });
  }, []);

  useEffect(() => {
    const modalEl = modalRef.current as HTMLElement;
    if (!modalEl) return;

    modalEl.addEventListener('hidden.bs.modal', onClose);

    return () => modalEl.removeEventListener('hidden.bs.modal', onClose);
  });

  useEffect(() => {
    if (!modal) return;

    if (opened) {
      modal?.show();
    } else {
      modal?.hide();
    }
  }, [opened, modal]);

  return (
    <div ref={modalRef} className="modal fade" tabIndex={-1} aria-labelledby={'Video'} aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-body">
            <div className="d-flex justify-content-end mb-3">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="youtube-modal__wrapper">{id && opened && <YouTube videoId={id} opts={options} />}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
