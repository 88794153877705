import React from 'react';
import { Routes, siteUrlShort } from '@/types';

interface MetadataProps {
  title?: string;
  fallbackTitle: string;
  description?: string;
  pageContext?: any;
  locale: any;
}

const getJsonLD = (originalPath, locale, language) => {
  const isEN = language === 'en';
  const postfix = isEN ? 'en/' : '';

  const defaultSchema = {
    '@context': 'http://schema.org',
    '@type': 'MedicalClinic',
    '@id': `https://lasercorrection.bg/${postfix}`,
    address: {
      '@type': 'PostalAddress',
      addressCountry: locale?.g.bulgaria,
      addressLocality: locale?.g.plovdiv,
      postalCode: '4000',
      streetAddress: locale?.g.address,
    },
    logo: `https://lasercorrection.bg/img/luxor-logo${isEN ? '-en' : ''}.webp`,
    name: locale?.g.title,
    openingHours: ['Mo-Sa 9:00-20:00'],
    geo: {
      '@type': 'GeoCoordinates',
      latitude: 42.158695,
      longitude: 24.740738,
    },
    telephone: '+359 877 881 115',
    sameAs: `https://lasercorrection.bg/${postfix}`,
    url: `https://lasercorrection.bg/${postfix}`,
    availableService: locale?.g['available-service'],
    description: locale?.g['general-description'],
    image: 'https://www.luxorclinic.com/img/1-tablet.webp',
    hasMap: 'https://goo.gl/maps/oXinJpAZasT2',
  };

  const astigmatismSchema = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://lasercorrection.bg/${postfix}astigmatism/`,
    },
    headline: locale?.g['astigmatism-headline'],
    image: {
      '@type': 'ImageObject',
      url: `https://lasercorrection.bg/img/${language}-astigmatism.webp`,
      height: 800,
      width: 800,
    },
    datePublished: '2017-09-05T08:00:00+08:00',
    dateModified: '2017-09-05T08:00:00+08:00',
    author: {
      '@type': 'Person',
      name: locale?.team.DR_GEORGI_TASKOV,
    },
    publisher: {
      '@type': 'Organization',
      name: locale?.g.title,
      logo: {
        '@type': 'ImageObject',
        url: `https://lasercorrection.bg/img/luxor-logo${isEN ? '-en' : ''}.webp`,
        width: 300,
        height: 57,
      },
    },
    description: locale?.g['astigmatism-description'],
  };

  const farsightSchema = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://lasercorrection.bg/${postfix}far-sightedness-hypermetropia/`,
    },
    headline: locale?.g['farsightness-headline'],
    image: {
      '@type': 'ImageObject',
      url: `https://lasercorrection.bg/img/${language}-far-sightedness.webp`,
      height: 800,
      width: 800,
    },
    datePublished: '2017-09-05T08:00:00+08:00',
    dateModified: '2017-09-05T08:00:00+08:00',
    author: {
      '@type': 'Person',
      name: locale?.team.DR_GEORGI_TASKOV,
    },
    publisher: {
      '@type': 'Organization',
      name: locale?.g.title,
      logo: {
        '@type': 'ImageObject',
        url: `https://lasercorrection.bg/img/luxor-logo${isEN ? '-en' : ''}.webp`,
        width: 300,
        height: 57,
      },
    },
    description: locale?.g['farsightness-description'],
  };

  const nearsightSchema = {
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `https://lasercorrection.bg/${postfix}short-sightedness-myopia/`,
    },
    headline: locale?.g['shortsightness-headline'],
    image: {
      '@type': 'ImageObject',
      url: `https://lasercorrection.bg/img/${language}-short-sightedness.webp`,
      height: 800,
      width: 800,
    },
    datePublished: '2017-09-05T08:00:00+08:00',
    dateModified: '2017-09-05T08:00:00+08:00',
    author: {
      '@type': 'Person',
      name: locale?.team.DR_GEORGI_TASKOV,
    },
    publisher: {
      '@type': 'Organization',
      name: locale?.g.title,
      logo: {
        '@type': 'ImageObject',
        url: `https://lasercorrection.bg/img/luxor-logo${isEN ? '-en' : ''}.webp`,
        width: 300,
        height: 57,
      },
    },
    description: locale?.g['shortsightness-description'],
  };

  return (
    <>
      <script type="application/ld+json">{JSON.stringify(defaultSchema, null, 4)}</script>
      {originalPath.includes(Routes.ASTIGMATISM) && (
        <script type="application/ld+json">{JSON.stringify(astigmatismSchema)}</script>
      )}
      {originalPath.includes(Routes.MYOPIA) && (
        <script type="application/ld+json">{JSON.stringify(nearsightSchema)}</script>
      )}
      {originalPath.includes(Routes.HYPERMETROPIA) && (
        <script type="application/ld+json">{JSON.stringify(farsightSchema)}</script>
      )}
    </>
  );
};

export const Metadata: React.FC<MetadataProps> = (props) => {
  const { title = '', description = '', fallbackTitle, pageContext, locale } = props;
  const {
    i18n: { originalPath },
    language,
  } = pageContext;

  const langFix = language === 'bg' ? '' : '/en';

  return (
    <>
      <title>{title || fallbackTitle}</title>
      {description && <meta name="description" content={description} />}
      <link rel="canonical" href={`${siteUrlShort}${langFix}${originalPath}`} />
      <link rel="alternate" href={`${siteUrlShort}${originalPath}`} hrefLang="bg" />
      <link rel="alternate" href={`${siteUrlShort}/en${originalPath}`} hrefLang="en" />
      <link rel="alternate" href={`${siteUrlShort}${originalPath}`} hrefLang="x-default" />
      {getJsonLD(originalPath, locale, language)}
    </>
  );
};
