import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import './home-campaigns.scss';
import { Routes } from '@/types';

export const HomeCampaigns = () => {
  const { t } = useI18next();

  return (
    <section className="bg-light py-3">
      <div className="container-fluid container-fluid--custom">
        <div className="row row-cols-1 row-cols-md-2 ">
          <div className="col">
            <h1 className="h6 text-uppercase mb-3 fw-semibold">{t('home.latest-news')}</h1>
            <div className="d-flex align-items-center">
              <img
                src="/img/campaigns/refractive-surgery-sml.webp"
                alt={t('home.latest-news-item.1')}
                width={150}
                height={81}
                loading="lazy"
                className="me-3"
              />
              <div>
                <h2 className="home-campaigns__title">
                  <Link to={Routes.CAMPAIGNS}>{t('home.latest-news-item.1')} </Link>
                </h2>
                <div className="small">{t('home.latest-news-item.1-date')}</div>
              </div>
            </div>
          </div>

          <div className="col">
            <h1 className="h6 text-uppercase mb-3 mt-3 mt-md-0 fw-semibold">{t('home.latest-events')}</h1>
            <div className="d-flex align-items-center">
              <img
                src="/img/campaigns/intraocular-lenses-sml.webp"
                alt={t('home.latest-news-item.2')}
                width={150}
                height={81}
                loading="lazy"
                className="me-3"
              />
              <div>
                <h2 className="home-campaigns__title">
                  <Link to={Routes.CAMPAIGNS}>{t('home.latest-events-item.1')} </Link>
                </h2>
                <div className="small">{t('home.latest-events-item.1-date')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
